
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import EventsTableExpandedView from '@/components/careConsole/EventsTableExpandedView.vue';
import WidgetDefinition, { ParamValues } from '@/models/Charts/widgetDefinition';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';

@Component({
  components: {
    EventsTableExpandedView,
  },
})
export default class ModalWrappedEventsTableExpandedView extends Vue {
  @Prop({ required: true })
  private selectedDeviceIds!: string[];

  @Prop({ required: true })
  private selectedTimeFilter!: [string, string];

  @Prop({ required: true })
  private selectedDateGrouping!: FilterTimeAxisSpanEnum;

  @Prop({ required: true })
  private widgetDefinition!: WidgetDefinition;

  @Prop({ default: () => ({}) })
  private paramValues!: ParamValues;

  @Prop()
  private module?: string;

  @Prop()
  private timestamp?: string;

  // Needed by Chart when opening from a double click on a chart
  @Prop()
  private onClose?: () => void;

  private modalOpen = true;

  @Watch('modalOpen')
  private onModalOpenChanged() {
    if (!this.modalOpen && !!this.onClose) {
      this.onClose();
    }
  }
}
